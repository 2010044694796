import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InstanceTrackerService {
    private pingInterval: any;

    constructor() {}

    startPing() {
        this.updatePing();
        this.pingInterval = setInterval(() => {
            this.updatePing();
        }, 30000); // 30 seconds
    }

    stopPing() {
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }
    }

    private updatePing() {
        const currentTime = new Date().toISOString();
        localStorage.setItem('ping', currentTime);
    }
}
