import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddAdditionalServiceSaveModel } from 'app/tos-operations/models/AddAdditionalService.save.model';
import { AddEditMotOnServiceRequestSaveModel } from 'app/tos-operations/models/AddEditMotOnServiceRequest.save.model';
import { CreateServiceRequestSaveModel } from 'app/tos-operations/models/CreateServiceRequest.save.model';
import { GetContainerDataForContainerNumberModel } from 'app/tos-operations/models/GetContainerDataForContainerNumber.model';
import { MigrationExcelFileModel } from 'app/tos-operations/models/MigrationExcelFile.model';
import { RemoveAdditionalServiceFromServiceRequestSaveModel } from 'app/tos-operations/models/RemoveAdditionalServiceFromServiceRequest.save.model';
import { RemoveMotFromServiceRequestSaveModel } from 'app/tos-operations/models/RemoveMotFromServiceRequest.save.model';
import { RemoveServiceRequestVersionShipmentSaveModel } from 'app/tos-operations/models/RemoveServiceRequestVersionShipment.save.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ServiceRequestService {
    constructor(private http: HttpClient) {}

    updateVesselServiceRequest(model: any): Observable<any> {
        return this.http.post<any>('api:///serviceRequest/UpdateVesselServiceRequest', model);
    }

    fetchReport(id: number): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/FetchReport`, { userTime: new Date().toISOString(), id: id });
    }

    fetchFullReport(id: number): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/FetchFullReport`, {
            userTime: new Date().toISOString(),
            id: id
        });
    }

    getEditVesselServiceRequestData(id: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetEditVesselServiceRequestData/${id}`);
    }

    getServiceRequestSumData(serviceRequestId: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetServiceRequestSumData/${serviceRequestId}`);
    }

    getServiceRequestList(filter: any): Observable<any> {
        return this.http.post('api:///ServiceRequest/GetServiceRequestList', filter);
    }

    createVesselServiceRequest(body: any): Observable<any> {
        return this.http.post('api:///serviceRequest/CreateNewVesselServiceRequest', body);
    }

    addNavisItems(body: any) {
        return this.http.post('api:///serviceRequest/AddNavisItems', body);
    }

    addAttachment(model: any) {
        return this.http.post<any>(`api:///ServiceRequest/AddAttachment`, model);
    }

    removeAttachment(model: any) {
        return this.http.post<any>(`api:///ServiceRequest/RemoveAttachment`, model);
    }

    sendVesselServiceRequest(
        serviceRequestId: any,
        user: any,
        relations: any[],
        isDataFromCargoList: boolean,
        cargoListIds: any[]
    ): Observable<any> {
        const body = {
            serviceRequestId: serviceRequestId,
            user: user,
            serviceRequestRelationDTO: relations,
            isDataFromCargoList: isDataFromCargoList,
            cargoListIds: cargoListIds
        };
        return this.http.post('api:///ServiceRequest/SendVesselServiceRequest', body);
    }

    sendLandServiceRequest(serviceRequestId: any, user: any): Observable<any> {
        const body = {
            serviceRequestId: serviceRequestId,
            user: user
        };
        return this.http.post('api:///ServiceRequest/SendLandServiceRequest', body);
    }

    getManipulationsForSystemAndServiceRequestType(system: string, serviceRequestType: string): Observable<any> {
        const body = { system, serviceRequestType };
        return this.http.post('api:///ServiceRequest/GetManipulationsForSystemAndServiceRequestType', body);
    }

    getCreateDataForLandServiceRequest(landServiceRequestId: number): Observable<any> {
        return this.http.get(`api:///ServiceRequest/GetCreateDataForLandServiceRequest/${landServiceRequestId}`);
    }

    getDataForVesselServiceRequest(serviceRequestId: number): Observable<any> {
        return this.http.get(`api:///ServiceRequest/GetDataForVesselServiceRequest/${serviceRequestId}`);
    }

    updateLandServiceRequest(dto: any): Observable<any> {
        return this.http.post<any>('api:///serviceRequest/UpdateLandServiceRequest', dto);
    }

    populateContainerDataOnLandSR(containerNumber: string, user: any, serviceRequestId: number): Observable<any> {
        const body = { containerId: containerNumber, user: user, serviceRequestId: serviceRequestId };
        return this.http.post(`api:///ServiceRequest/PopulateContainerDataOnLandSR/`, body);
    }

    updateDataOnServiceRequest(serviceRequestId: number): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/updateDataOnServiceRequest/${serviceRequestId}`, null);
    }

    cloneServiceRequest(cloneModel: any): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/cloneServiceRequest/`, cloneModel);
    }

    deleteVesselServiceRequest(serviceRequestId: number) {
        return this.http.get<any>(`api:///serviceRequest/DeleteVesselServiceRequest/${serviceRequestId}`);
    }

    completeServiceRequestRealization(serviceRequestId: number): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/CompleteServiceRequestRealization/${serviceRequestId}`, null);
    }

    uploadContainerMigration(file: MigrationExcelFileModel): Observable<any> {
        return this.http.post<any>(`api:///migration/UploadContainerMigration/`, file);
    }

    addServiceRequestWorkOrder(serviceRequestId: number, workOrderIds: number[]) {
        return this.http.post<any>(`api:///serviceRequest/AddServiceRequestWorkOrder`, {
            serviceRequestId: serviceRequestId,
            workOrderIds: workOrderIds
        });
    }

    createTruckDeklot(serviceRequestId: number) {
        return this.http.post(`api:///ServiceRequest/CreateDeklotTruck/${serviceRequestId}`, null);
    }

    getPortsForUserOnCreateServiceRequest(): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetPortsForUserOnCreateServiceRequest/`);
    }

    getTerminalsOnServiceRequest(port: string): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetTerminalsOnServiceRequest/${port}`);
    }

    getTerminalOperatorsOnServiceRequest(terminal: string): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetTerminalOperatorsOnServiceRequest/${terminal}`);
    }

    getSecondaryTerminalsOnServiceRequest(
        terminalOperator: number,
        terminal: string,
        tosSystem: string
    ): Observable<any> {
        return this.http.get<any>(
            `api:///serviceRequest/GetSecondaryTerminalsOnServiceRequest/${terminalOperator}/${terminal}/${tosSystem}`
        );
    }

    getTosSystemsOnServiceRequest(terminal: string, terminalOperator: number): Observable<any> {
        return this.http.get<any>(
            `api:///serviceRequest/getTosSystemsOnServiceRequest/${terminal}/${terminalOperator}`
        );
    }

    getShipmentTypesOnServiceRequest(tosSystem: string): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetShipmentTypesOnServiceRequest/${tosSystem}`);
    }

    getAdditionalServicesOnServiceRequest(tosSystem: string): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetAdditionalServicesOnServiceRequest/${tosSystem}`);
    }

    getAdditionalServicesBasedOnManipulation(manipulation: string): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetAdditionalServicesBasedOnManipulation/${manipulation}`);
    }

    getManipulationsOnServiceRequest(tosSystem: string): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetManipulationsOnServiceRequest/${tosSystem}`);
    }

    getManipulationSrTypeAndMot(manipulation: string): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetManipulationSrTypeAndMot/${manipulation}`);
    }

    getVesselFromVesselVisit(vesselVisitId: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetVesselFromVesselVisit/${vesselVisitId}`);
    }

    getVesselVisitsBasedOnManipulation(manipulation: string): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetVesselVisitsBasedOnManipulation/${manipulation}`);
    }

    getLoadingVesselVisits(): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetLoadingVesselVisits`);
    }

    createServiceRequest(body: CreateServiceRequestSaveModel): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/CreateServiceRequest/`, body);
    }

    getShippingLinesAndAgents(): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetShippingLinesAndAgents/`);
    }

    addMotOnServiceRequest(body: AddEditMotOnServiceRequestSaveModel): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/AddMotOnServiceRequest/`, body);
    }

    editMotOnServiceRequest(body: AddEditMotOnServiceRequestSaveModel): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/EditMotOnServiceRequest/`, body);
    }

    removeMotFromServiceRequest(body: RemoveMotFromServiceRequestSaveModel): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/RemoveMotFromServiceRequest/`, body);
    }

    removeServiceRequestVersionShipment(body: RemoveServiceRequestVersionShipmentSaveModel): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/RemoveServiceRequestVersionShipment/`, body);
    }

    sendServiceRequestToTerminal(serviceRequestId: number): Observable<any> {
        return this.http.post(`api:///ServiceRequest/SendServiceRequestToTerminal/${serviceRequestId}`, null);
    }

    getShipmentLinkedForMotOnServiceRequest(
        serviceRequestId: number,
        serviceRequestVersionShipmentId: number
    ): Observable<any> {
        return this.http.get(
            `api:///ServiceRequest/GetShipmentLinkedForMotOnServiceRequest/${serviceRequestId}/${serviceRequestVersionShipmentId}`
        );
    }

    getShipmentLinkedForContainerOnServiceRequest(
        serviceRequestId: number,
        serviceRequestVersionShipmentId: number
    ): Observable<any> {
        return this.http.get(
            `api:///ServiceRequest/GetShipmentLinkedForContainerOnServiceRequest/${serviceRequestId}/${serviceRequestVersionShipmentId}`
        );
    }

    getShipmentsNotLinkedToMOTstatus(serviceRequestId: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetShipmentsNotLinkedToMOTstatus/${serviceRequestId}`);
    }

    getMOTNotLinkedToShipmentStatus(serviceRequestId: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetMOTNotLinkedToShipmentStatus/${serviceRequestId}`);
    }

    getOrganizationShippingLinesForServiceRequestVersion(serviceRequestVersionId: number): Observable<any> {
        return this.http.get<any>(
            `api:///serviceRequest/GetOrganizationShippingLinesForServiceRequestVersion/${serviceRequestVersionId}`
        );
    }

    getContainerTypeForISOType(isoTypeId: string): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetContainerTypeForISOType/${isoTypeId}`);
    }

    getContainerDataForContainerNumber(body: GetContainerDataForContainerNumberModel): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/GetContainerDataForContainerNumber/`, body);
    }

    acceptServiceRequest(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/AcceptServiceRequest`, dto);
    }

    declineServiceRequest(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/DeclineServiceRequest`, dto);
    }

    cancelServiceRequest(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/CancelServiceRequest`, dto);
    }

    completeServiceRequest(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/CompleteServiceRequest`, dto);
    }

    addAdditionalServiceOnServiceRequest(body: AddAdditionalServiceSaveModel): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/AddAdditionalServiceOnServiceRequest/`, body);
    }

    removeAdditionalServiceFromServiceRequest(
        body: RemoveAdditionalServiceFromServiceRequestSaveModel
    ): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/RemoveAdditionalServiceFromServiceRequest/`, body);
    }

    removeContainers(data: any): Observable<any> {
        return this.http.post(`api:///serviceRequest/RemoveServiceRequestVersionContainers`, data);
    }

    saveContainer(body: any): Observable<any> {
        return this.http.post('api:///serviceRequest/SaveServiceRequestVersionContainer', body);
    }

    getShippingLines(): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetShippingLinesForContainers`);
    }

    holdServiceRequest(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/HoldServiceRequest`, dto);
    }

    holdServiceRequestItems(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/HoldServiceRequestItems`, dto);
    }

    createServiceRequestCustomInspection(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/CreateServiceRequestCustomInspection`, dto);
    }

    createServiceRequestCustomInspectionFromContainers(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/CreateServiceRequestCustomInspectionFromContainers`, dto);
    }

    createServiceRequestCustomInspectionFromShipmentItems(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/CreateServiceRequestCustomInspectionFromShipmentItems`, dto);
    }

    getOrganizationsForServiceRequestMOTWagon(): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetOrganizationsForServiceRequestMOTWagon`);
    }

    getCargoHoldTypesForUser(): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetCargoHoldTypesForUser`);
    }

    getRealizationData(serviceRequestId: any): Observable<any> {
        return this.http.get(`api:///ServiceRequest/GetRealizationData/${serviceRequestId}`);
    }

    getServiceRequestVersionContainer(containerId: any): Observable<any> {
        return this.http.get(`api:///ServiceRequest/GetServiceRequestVersionContainer/${containerId}`);
    }

    deleteServiceRequest(serviceRequestId: number): Observable<any> {
        return this.http.post(`api:///ServiceRequest/DeleteServiceRequest/${serviceRequestId}`, null);
    }

    getEditDataForServiceEquest(serviceRequestId: any): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetEditDataForServiceEquest/${serviceRequestId}`);
    }

    getEditDataForVehicle(serviceRequestVersionVehicleId: any): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetEditDataForVehicle/${serviceRequestVersionVehicleId}`);
    }

    getEditDataForPassenger(serviceRequestVersionPassengerId: any): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetEditDataForPassenger/${serviceRequestVersionPassengerId}`);
    }

    editServiceRequestAdditionalData(model: any): Observable<any> {
        return this.http.post<any>('api:///serviceRequest/EditServiceRequestAdditionalData', model);
    }

    getCustomsHoldsForServiceRequest(serviceRequestId: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetCustomsHoldsForServiceRequest/${serviceRequestId}`);
    }

    uploadExcelOnServiceRequest(model: any): Observable<any> {
        return this.http.post<any>(`api:///ServiceRequest/UploadExcelOnServiceRequest`, model);
    }

    linkContainersToMots(model: any) {
        return this.http.post<any>(`api:///ServiceRequest/LinkServiceRequestVersionContainersToMots`, model);
    }

    getAllContainerIds(serviceRequestId: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetAllContainerIds/${serviceRequestId}`);
    }

    getAllItemIds(serviceRequestId: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetAllItemIds/${serviceRequestId}`);
    }

    getContainerVisitIds(filter: any): Observable<any> {
        return this.http.post('api:///ServiceRequest/GetAllContainerVisitIds', filter);
    }

    getContainerList(filter: any): Observable<any> {
        return this.http.post('api:///ServiceRequest/GetContainerList', filter);
    }

    getVesselVisitsForFromBulPCS(): Observable<any> {
        return this.http.get('api:///ServiceRequest/GetVesselVisitsForFromBulPCS');
    }

    getContainersFromBulPCS(filter: any): Observable<any> {
        return this.http.post('api:///ServiceRequest/GetContainersFromBulPCS', filter);
    }

    addContainersFromBulPCS(body: any) {
        return this.http.post('api:///serviceRequest/AddContainersFromBulPCS', body);
    }

    getServiceRequestVersionShipmentList(filter: any): Observable<any> {
        return this.http.post('api:///ServiceRequest/GetServiceRequestVersionShipmentList', filter);
    }

    getServiceRequestVersionShipmentDetailsList(filter: any): Observable<any> {
        return this.http.post('api:///ServiceRequest/GetServiceRequestVersionShipmentDetailsList', filter);
    }

    getServiceRequestVehiclesList(filter: any): Observable<any> {
        return this.http.post('api:///ServiceRequest/GetServiceRequestVehiclesList', filter);
    }

    getServiceRequestPassengersList(filter: any): Observable<any> {
        return this.http.post('api:///ServiceRequest/GetServiceRequestPassengersList', filter);
    }

    getContainersSummary(serviceRequestId: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetContainersSummary/${serviceRequestId}`);
    }

    addContainerMove(body: any): Observable<any> {
        return this.http.post<any>(`api:///serviceRequest/AddContainerMove`, body);
    }

    getLinesForContainers(agentId: number, cargoManifestId?: number, serviceRequestId?: number): Observable<any> {
        return this.http.get<any>(
            `api:///serviceRequest/GetLinesForContainers/${agentId ?? 0}/${cargoManifestId ?? 0}/${
                serviceRequestId ?? 0
            }`
        );
    }

    getServiceRequestAdditionalDataChanges(serviceRequestId: number): Observable<any> {
        return this.http.get<any>(`api:///serviceRequest/GetServiceRequestAdditionalDataChanges/${serviceRequestId}`);
    }

    getVehicleVisitList(filter: any) {
        return this.http.post('api:///VehicleVisit/GetVehicleVisitList', filter);
    }

    getVehicleVisit(id: number) {
        return this.http.get<any>(`api:///VehicleVisit/GetVehicleVisit/${id}`);
    }

    getServiceRequestVehiclesForPassengers(serviceRequestId: number) {
        return this.http.get<any>(`api:///serviceRequest/GetVehiclesForPassenger/${serviceRequestId}`);
    }

    getServiceRequests(vehicleVisitId: number) {
        return this.http.get<any>(`api:///VehicleVisit/GetServiceRequests/${vehicleVisitId}`);
    }

    getVehicleMoves(vehicleVisitId: number) {
        return this.http.get<any>(`api:///VehicleVisit/GetVehicleMoves/${vehicleVisitId}`);
    }

    confirmVehicleMoves(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/ConfirmVehicleMoves`, dto);
    }

    cancelVehicleMoves(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/CancelVehicleMoves`, dto);
    }

    deleteVehicleMoves(dto: any): Observable<any> {
        return this.http.post(`api:///ServiceRequest/DeleteVehicleMoves`, dto);
    }

    saveVehicle(model: any) {
        return this.http.post<any>(`api:///ServiceRequest/SaveServiceRequestVersionVehicle`, model);
    }

    savePassenger(model: any) {
        return this.http.post<any>(`api:///ServiceRequest/SaveServiceRequestVersionPassenger`, model);
    }

    removeVehicles(model: any) {
        return this.http.post<any>(`api:///ServiceRequest/RemoveServiceRequestVersionVehicles`, model);
    }

    removePassengers(model: any) {
        return this.http.post<any>(`api:///ServiceRequest/RemoveServiceRequestVersionPassengers`, model);
    }
}
