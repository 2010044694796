import { NoticeBoardService } from '@administration/notice-board/notice-board.service';
import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { EnvironmentNames } from '@common/knownTypes/EnvironmentNames';
import { isMobile } from '@common/utils/isMobile';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { StateService } from 'app/singleton-services/state.service';
import { environment } from 'environments/environment';
import { some } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter, tap } from 'rxjs/operators';
import { MenuItem } from '../../models/common/MenuItem';
import { UserModel } from '../../models/User.model';
import { ModalService, ModalWidth } from '../../services/modal.service';
import { currentUserSubject, UserService } from '../../services/user.service';
import { ChangeLanguageHeaderComponent } from '../change-language-header/change-language-header.component';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Output() toggleSidebar = new EventEmitter();
    user: UserModel;
    dark: boolean;

    buildInfo = environment.name;
    displayBuildInfo = environment.name !== EnvironmentNames.PRODUCTION;

    isMobile = isMobile();

    profileMenuItems = ['account', 'sign-out'];

    userLoggedOutMenuItems = [
        { language: 'en', text: marker('EN - English') },
        { language: 'bg', text: marker('BG - български') }
    ];

    notificationsCount: number = 0;

    constructor(
        private router: Router,
        private dialogService: ModalService,
        private translateService: TranslateService,
        private userService: UserService,
        private renderer: Renderer2,
        private kendoDialog: DialogService,
        private toastyService: ToastrService,
        private noticeBoardService: NoticeBoardService,
        private stateService: StateService
    ) {
        currentUserSubject.subscribe((user) => {
            let loggedout = localStorage.getItem('loggedout');
            if (loggedout != 'true') {
                this.user = user;
            }
        });
    }

    toggleDarkMode() {
        this.dark = !this.dark;
        if (this.dark) {
            this.renderer.removeClass(document.body, 'lightTheme');
            this.renderer.addClass(document.body, 'darkTheme');
            localStorage.setItem('dark', JSON.stringify(this.dark));
        } else {
            this.renderer.removeClass(document.body, 'darkTheme');
            this.renderer.addClass(document.body, 'lightTheme');
            localStorage.setItem('dark', JSON.stringify(this.dark));
        }
    }

    ngOnInit() {
        this.dark = false;
        this.translateService.use(localStorage.getItem('language') || 'en');
        if (environment.name === EnvironmentNames.STAGING) {
            this.buildInfo = 'TEST';
        }
        if (!this.user) this.getNotifications();
    }

    get currentLanguage() {
        return this.translateService.currentLang;
    }

    onToggleSidebar() {
        this.toggleSidebar.emit();
    }

    login() {
        this.router.navigate(['/login']);
    }

    logout() {
        this.dialogService
            .confirm(
                this.translateService.instant(marker('Sign Out')) as string,
                this.translateService.instant(marker('Are you sure you want to sign out?')) as string
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe((result) => {
                this.router.navigate(['/logout']);
                this.getNotifications();
            });
    }

    hasPermission(menuItem: MenuItem) {
        if (!this.user) {
            return false;
        }

        if (!menuItem.permissions || menuItem.permissions.length === 0) {
            return true;
        }

        return this.user && some(menuItem.permissions, (permission) => this.user.hasPermission(permission));
    }

    async changeLanguage() {
        const changeLanguageRef = this.kendoDialog.open({
            content: ChangeLanguageHeaderComponent,
            width: ModalWidth.sm
        });

        changeLanguageRef.content.instance.currentLanguage = this.currentLanguage;
        changeLanguageRef.content.instance.title = this.translateService.instant(marker('Change Language'));

        let languageId;

        changeLanguageRef.result
            .pipe(
                filter((res) => !(res instanceof DialogCloseResult)),
                tap((x) => (languageId = x))
            )
            .subscribe((x) => {
                if (x) {
                    if (!this.user) {
                        this.translateService.use(languageId.toLowerCase());
                        this.toastyService.success(this.translateService.instant(marker('Language changed.')));
                        localStorage.setItem('language', languageId.toLowerCase());
                    } else {
                        localStorage.setItem('language', languageId.toLowerCase());
                        this.userService
                            .setCurrentLanguage(languageId, this.stateService.getSignInType())
                            .then((data) => {
                                this.userService.setAuthenticationTokens(data.accessToken, data.refreshToken);
                                this.translateService.use(languageId.toLowerCase());
                                this.toastyService.success(this.translateService.instant(marker('Language changed.')));
                                window.location.reload();
                            });
                    }
                }
            });
    }

    openNotificationsGridModal() {
        this.dialogService.openNotificationsGridModal();
    }

    private getNotifications(): void {
        this.noticeBoardService.getNotificationsOnLogin().subscribe((response) => {
            this.notificationsCount = response.length;
        });
    }
}
