import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@common/services/authentication.service';
import { InstanceTrackerService } from '@common/services/instance-tracker.service';

@Component({
    template: ''
})
export class LogoutComponent {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private pingService: InstanceTrackerService
    ) {
        this.pingService.stopPing();
        localStorage.removeItem('ping');

        if (this.router.url == '/logout/local') {
            this.authenticationService.logoutAndRedirectToLogin(null, true);
        } else {
            this.authenticationService.logoutAndRedirectToLogin();
        }
    }
}
